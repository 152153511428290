import DataDriven from "@modules/common/icons/data-driven";
import PillBubble from "./PillBubble";
import Profitable from "@modules/common/icons/profitable";
import Clock from "@modules/common/icons/clock";
import Gear from "@modules/common/icons/gear";
import GearStar from "@modules/common/icons/gear-star";

function Statement() {
  return (
    <div className="w-full px-4 sm:px-24 sm:text-4xl !leading-loose text-darkGreen text-center tracking-wider">
      Empower your business with
      <PillBubble
        text="profitable"
        description="Every reward sent translates into measurable returns, ensuring your investment drives tangible results."
        Icon={Profitable}
      />
      , personalized,
      <PillBubble
        text="data-driven"
        description="Our loyalty engine makes decisions based on in-depth analysis of customer behavior and segmentation."
        Icon={DataDriven}
      />
      loyalty. Ensure the right reward reaches the right customer at the
      <PillBubble
        text="perfect moment"
        description="By monitoring customer touchpoints in real-time, our engine triggers communication at just the right moment, ensuring order fulfillment."
        Icon={Clock}
      />
      -
      <PillBubble
        text="without the manual work"
        description="All this is completely automated. Once your brand is set up, you won’t have to lift a finger to see your business grow."
        Icon={Gear}
      />
      . We help you stay consistently engaged with your customers. Through
      automated reminders and strategic touchpoints, your brand will always be
      in the
      <PillBubble
        text="spotlight"
        description="We’re integrated with an enterprise-grade communication provider, ensuring your customers stay consistently engaged with your brand."
        Icon={GearStar}
      />
      .
    </div>
  );
}

export default Statement;
