import React from "react";
import { IconProps } from "types/icon";

const MenuManagement: React.FC<IconProps> = ({
  size = "16",
  color = "currentColor",
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 37 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M26.037 13.75H34.2593C34.9861 13.75 35.6833 13.4603 36.1973 12.9445C36.7112 12.4288 37 11.7293 37 11V2.75C37 2.02065 36.7112 1.32118 36.1973 0.805456C35.6833 0.289731 34.9861 0 34.2593 0H26.037C25.3101 0 24.613 0.289731 24.099 0.805456C23.5851 1.32118 23.2963 2.02065 23.2963 2.75V5.5H21.9259C20.4721 5.5 19.0779 6.07946 18.0499 7.11091C17.022 8.14236 16.4444 9.54131 16.4444 11V15.125H10.963V13.75C10.963 13.0207 10.6742 12.3212 10.1602 11.8055C9.64623 11.2897 8.94911 11 8.22222 11H2.74074C2.01385 11 1.31673 11.2897 0.802744 11.8055C0.288756 12.3212 0 13.0207 0 13.75V19.25C0 19.9793 0.288756 20.6788 0.802744 21.1945C1.31673 21.7103 2.01385 22 2.74074 22H8.22222C8.94911 22 9.64623 21.7103 10.1602 21.1945C10.6742 20.6788 10.963 19.9793 10.963 19.25V17.875H16.4444V22C16.4444 23.4587 17.022 24.8576 18.0499 25.8891C19.0779 26.9205 20.4721 27.5 21.9259 27.5H23.2963V30.25C23.2963 30.9793 23.5851 31.6788 24.099 32.1945C24.613 32.7103 25.3101 33 26.037 33H34.2593C34.9861 33 35.6833 32.7103 36.1973 32.1945C36.7112 31.6788 37 30.9793 37 30.25V22C37 21.2707 36.7112 20.5712 36.1973 20.0555C35.6833 19.5397 34.9861 19.25 34.2593 19.25H26.037C25.3101 19.25 24.613 19.5397 24.099 20.0555C23.5851 20.5712 23.2963 21.2707 23.2963 22V24.75H21.9259C21.199 24.75 20.5019 24.4603 19.9879 23.9445C19.4739 23.4288 19.1852 22.7293 19.1852 22V11C19.1852 10.2707 19.4739 9.57118 19.9879 9.05546C20.5019 8.53973 21.199 8.25 21.9259 8.25H23.2963V11C23.2963 11.7293 23.5851 12.4288 24.099 12.9445C24.613 13.4603 25.3101 13.75 26.037 13.75ZM8.22222 19.25H2.74074V13.75H8.22222V19.25ZM26.037 22H34.2593V30.25H26.037V22ZM26.037 2.75H34.2593V11H26.037V2.75Z"
        fill={color}
      />
    </svg>
  );
};

export default MenuManagement;
