import Head from "@modules/common/components/head";
import Business from "@modules/home/business";
import DemoForm from "@modules/home/contact-form";
import Customization from "@modules/home/customization";
import Hero from "@modules/home/hero";
import OurClients from "@modules/home/our-clients";
import PosSystems from "@modules/home/pos-systems";
import Statement from "@modules/home/statement";

export default function Home() {
  return (
    <div className="flex flex-col gap-20  lg:gap-32 lg:pt-16">
      <Head />
      <Hero />
      <Statement />
      <Business />
      <Customization />
      <PosSystems /> <OurClients />
      <DemoForm />
    </div>
  );
}

// export async function getStaticProps() {
//   const apiUrl = `${process.env.STRAPI_URL}/restaurants`;
//   const headers = {
//     Authorization: `Bearer ${process.env.STRAPI_TOKEN}`,
//   };

//   let restaurants = [];

//   try {
//     const response = await fetch(apiUrl, { headers });
//     if (response.ok) {
//       restaurants = await response.json();
//     } else {
//       console.error(`Failed to fetch data from Strapi: ${response.status}`);
//     }
//   } catch (error) {
//     console.error(`Error fetching data from Strapi: ${error}`);
//   }

//   console.log("restaurants", restaurants);

//   return {
//     props: {
//       restaurants,
//     },
//     revalidate: 10, // Revalidate every 10 seconds for fresh data
//   };
// }
