import LocationPin from "@modules/common/icons/location-pin";
import { ReactNode } from "react";

interface AccordionProps {
  title: string;
  isOpen: boolean;
  onToggle: () => void;
  children: ReactNode;
}

function Accordion({ title, isOpen, onToggle, children }: AccordionProps) {
  return (
    <div
      className={`w-full  border-2 rounded-[1.6rem] lg:rounded-[1.8rem] overflow-hidden   ${
        isOpen ? "border-primary bg-lightBlue" : "border-gray-300 bg-gray-100"
      }`}
    >
      {/* Accordion Header */}
      <button
        onClick={onToggle}
        className="flex justify-between items-center w-full px-4 py-2 lg:py-3 rounded-[1.8rem] lg:rounded-[1.8rem] bg-lightPrimary text-darkGreen "
      >
        <span className="text-xl font-semibold">{title}</span>
        <div
          className={`text-2xl border rounded-full border-darkGreen p-2 transition-all duration-500 ${
            isOpen ? "bg-darkGreen text-lightPrimary" : "bg-none text-darkGreen"
          }`}
        >
          <LocationPin className="h-3 w-3 lg:h-7 lg:w-7 " />
        </div>
      </button>

      {/* Accordion Content with Transition */}
      <div
        className={`transition-[max-height] duration-500 ease-in-out ${
          isOpen ? "max-h-[400px]" : "max-h-[0px]"
        } overflow-hidden`}
      >
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
}

export default Accordion;
