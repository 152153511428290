import ArrowRightSmall from "@modules/common/icons/arrow-right-small";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";

function PaginatedCards(props: {
  clients: {
    name: string;
    logoSvgSrc: string;
    link: string;
  }[];
}) {
  const CARDS_PER_PAGE = 6; // Number of cards to show per page

  const [currentPage, setCurrentPage] = useState(0);

  // Calculate total pages
  const totalPages = Math.ceil(props.clients.length / CARDS_PER_PAGE);

  // Paginated cards
  const paginatedCards = props.clients.slice(
    currentPage * CARDS_PER_PAGE,
    currentPage * CARDS_PER_PAGE + CARDS_PER_PAGE
  );

  // Handlers
  const goToNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToPage = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="w-full px-4  py-3">
      {/* Card Grid */}
      <div className="grid grid-cols-3 gap-2 lg:gap-4">
        {paginatedCards.map((card, index) => (
          <div
            key={index}
            className="relative  h-[70px] flex items-center justify-center "
          >
            <Link
              className="text-sm text-gray-500 py-2"
              href={card.link}
              target="_blank"
            >
              <Image
                src={card.logoSvgSrc}
                alt={card.name}
                width={100}
                height={100}
                style={{ alignItems: "center" }}
              />
            </Link>
          </div>
        ))}
      </div>

      {/* Navigation Controls */}
      <div className="flex items-center justify-start space-x-4 mt-6">
        {/* Previous Button */}
        <button
          id="nav-prev"
          aria-label="nav previous"
          onClick={goToPrevPage}
          disabled={currentPage === 0}
          className={`p-2 lg:p-5  rounded-full border-0 outline outline-1 md:outline-1  hover:bg-primary hover:text-terryWhite transition-all duration-200  ${
            currentPage === 0
              ? "!bg-gray-200 !text-gray-400  outline-gray-200"
              : " outline-primary  text-primary hover:bg-primary-dark"
          }`}
        >
          <ArrowRightSmall className="rotate-180 h-3 w-3 lg:h-4 lg:w-4" />
        </button>
        {/* Next Button */}
        <button
          onClick={goToNextPage}
          id="nav-next"
          aria-label="nav next"
          disabled={currentPage === totalPages - 1 || totalPages === 0}
          className={`p-2 lg:p-5 outline outline-1 md:outline-1  rounded-full border-0  hover:bg-primary hover:text-terryWhite transition-all duration-200  ${
            currentPage === totalPages - 1 || totalPages === 0
              ? "!bg-gray-200 !text-gray-400  outline-gray-200"
              : " outline-primary  text-primary hover:bg-primary-dark"
          }`}
        >
          <ArrowRightSmall className="h-3 w-3 lg:h-4 lg:w-4" />
        </button>{" "}
        {/* Dotted Pagination */}
        <div className="pl-4 flex items-center lg:space-x-3 space-x-3">
          {Array.from({ length: totalPages }, (_, index) => (
            <div
              key={index}
              id={"nav" + index}
              onClick={() => goToPage(index)}
              className={`w-2 h-2 rounded-full outline-offset-[-0.5px] ${
                index === currentPage
                  ? "bg-primary outline outline-1 outline-primary"
                  : "bg-none hover:bg-primary-light outline outline-1 outline-darkGreen"
              }`}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PaginatedCards;
