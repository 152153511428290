import InfiniteScrollCarousel from "@modules/common/components/Infinite-scroll";
import React from "react";

function PosSystems() {
  return (
    <div className="w-full justify-center text-darkGreen justify-items-center ">
      <div className="w-full flex flex-col lg:flex-row text-left px-4 lg:px-16 justify-between">
        <h2 className="text-2xl lg:text-4xl font-semibold max-w-screen-xsmall mt-3">
          Seamlessly blending <br></br> into your operations
        </h2>
        <h3 className="text-sm lg:text-base  lg:px-2 py-0.5 rounded-md mr-8">
          Connected to over 140+ POS systems
        </h3>
      </div>
      <div className="w-full relative overflow-x-clip ">
        {/* Gradient Overlay - Left */}
        <div className="absolute top-0 left-0 h-full w-1/4 lg:w-1/6 bg-gradient-to-r from-white  to-transparent pointer-events-none z-10"></div>

        {/* Gradient Overlay - Right */}
        <div className="absolute top-0 right-0 h-full w-1/4 lg:w-1/6  bg-gradient-to-l from-white to-transparent pointer-events-none z-10"></div>

        {/* Infinite Scroll Carousel */}
        <InfiniteScrollCarousel />
      </div>
    </div>
  );
}

export default PosSystems;
