import React, { useRef, useState } from "react";
import clsx from "clsx";
import { useOutsideClick } from "@lib/hooks/use-click-outside";
import X from "@modules/common/icons/x";
import { IconProps } from "types/icon";

interface Props {
  text: string;
  description: string;
  Icon: React.FC<IconProps>;
}

function PillBubble({ text, description, Icon }: Props) {
  const bubbleRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setHovered] = useState(false);

  useOutsideClick(bubbleRef, () => {
    setIsOpen(false);
  });

  return (
    <div className="relative inline-block mx-2 my-1">
      {/* Button */}
      <button
        ref={bubbleRef}
        className={clsx(
          "px-4  bg-lightPrimary border-primary border-2 rounded-[50px] transition-all z-10 relative ",
          isHovered || isOpen ? "sm:z-30 " : ""
        )}
        onClick={() => setIsOpen((prev) => !prev)}
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
      >
        {text}
      </button>
      {/* Text Area */}
      <span
        className={clsx(
          "hidden sm:flex  absolute left-0 top-0 right-0 pb-5 px-1 bg-primary text-terryWhite text-sm rounded-[1.1rem] shadow-lg transform transition-all duration-500 overflow-clip opacity-70 max-h-full",
          isOpen ? "!opacity-100 z-20 !max-h-80" : "",
          "sm:rounded-[40px] sm:p-4 sm:flex"
        )}
      >
        <br className="hidden sm:flex" />
        <br className="hidden sm:flex" />
        <br /> <br />
        {description}
      </span>
      {/* X */}
      <span
        className={clsx(
          "hidden sm:flex absolute left-0  sm:top-[15%] top-[10%] p-0.5 sm:p-2  border-2 border-primary text-sm rounded-[40px] shadow-lg transform transition-all duration-500 overflow-clip opacity-70 max-h-full cursor-pointer",
          isHovered || isOpen
            ? "!opacity-100  sm:-translate-y-[135%] -translate-y-[130%]"
            : "",
          isOpen
            ? "bg-primary text-terryWhite"
            : "bg-lightPrimary text-primary "
        )}
        onClick={() => setIsOpen(false)}
      >
        {isOpen ? (
          <X size={30} />
        ) : (
          <Icon size={30} className="hidden lg:flex" />
        )}
      </span>

      <span
        className={clsx(
          "-bottom-full block sm:hidden fixed left-0 right-0  bg-primary text-terryWhite z-30 text-sm rounded-t-[1.9rem] shadow-lg transform transition-all duration-500  opacity-70 max-h-full ",
          isOpen ? "!bottom-0 !opacity-100" : "",
          "sm:rounded-[40px] sm:p-4 sm:flex"
        )}
      >
        <span
          className={clsx(
            "sm:hidden absolute left-[0]  top-[0] p-2  border-2 bg-lightPrimary text-primary border-primary text-sm rounded-[40px] shadow-lg transform transition-all duration-500 overflow-clip opacity-70 max-h-full cursor-pointer",
            isOpen
              ? "!opacity-100  sm:-translate-y-[135%] -translate-y-[130%]"
              : ""
          )}
        >
          <Icon size={30} className="sm:hidden" />
        </span>
        <span className="flex text-darkGreen justify-between w-full text-2xl bg-lightPrimary border-primary border-[2px] rounded-[2rem] py-3 pl-8 pr-6 place-items-center">
          {text} <X size={30} className="sm:hidden " />
        </span>
        <span className="flex px-7 text-base text-left py-10">
          {" "}
          {description}
        </span>
      </span>

      {/* Mobile Backdrop */}

      <div
        className={clsx(
          "fixed inset-0 bg-black bg-opacity-0 z-20 sm:hidden transition-all duration-500",
          isOpen ? "pointer-events-auto !bg-opacity-50" : "pointer-events-none"
        )}
        onClick={() => setIsOpen(false)} // Close bubble on backdrop click
      ></div>
    </div>
  );
}

export default PillBubble;
