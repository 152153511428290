import { useState } from "react";
import Accordion from "./Accordions"; // Import the Accordion component
import PaginatedCards from "./PaginatedCards";

import DynamicLottiePlayer from "@modules/common/components/dynamic-lottie-player";
import { clientsByCountry } from "db";

function OurClients() {
  const countries = ["Egypt", "Canada"];

  const [openCountry, setOpenCountry] = useState<string | null>("Egypt");
  const [previousCountry, setPreviousCountry] = useState<string | null>(
    "Canada"
  );

  // Function to handle country selection
  const handleCountrySelect = (country: string) => {
    if (country !== openCountry) {
      setPreviousCountry(openCountry); // Update previous country
      setOpenCountry(country); // Set new current country
    }
  };

  // Determine the transition GIF based on current and previous country
  const currentTransition =
    previousCountry && openCountry
      ? `${previousCountry}${openCountry.replace(" ", "")}` // Concatenate without spaces
      : "";

  return (
    <div className="flex flex-col justify-center items-center w-full text-center justify-items-center px-4 lg:px-16">
      {/* Section Header */}
      <p className="text-sm lg:text-base border-[1px] border-primary text-darkGreen px-2 py-0.5 rounded-md w-fit mb-6 text-center">
        Our Clients
      </p>
      <h2 className="lg:text-[4vw] lg:text-6xl text-3xl">
        Trusted by businesses <br />
        <span className="text-primary">around the world</span>
      </h2>

      {/* Main Layout */}
      <div className="w-full flex gap-4 lg:flex-row flex-col-reverse mt-16">
        {/* Accordion Section */}
        <div className="lg:w-1/2 flex flex-col gap-4 items-start justify-center">
          {countries.map((country) => (
            <Accordion
              key={country}
              title={country}
              isOpen={openCountry === country}
              onToggle={() => handleCountrySelect(country)}
            >
              <PaginatedCards
                clients={
                  clientsByCountry[
                    country.toLocaleLowerCase() as keyof typeof clientsByCountry
                  ]
                }
              />
            </Accordion>
          ))}
        </div>

        {/* Globe GIF Section */}
        <div className="lg:w-1/2 h-[30vh] min-h-[65vw] lg:h-auto lg:!min-h-min relative !content-start lg:py-4 overflow-x-clip self-center">
          <DynamicLottiePlayer src={currentTransition} />
        </div>
      </div>
    </div>
  );
}

export default OurClients;
