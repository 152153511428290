import ArrowRight from "@modules/common/icons/arrow-right";
import Link from "next/link";

type UnderlineLinkProps = {
  href: string;
  children?: React.ReactNode;
  className?: string;
  areaLabel?: string;
};

const UnderlineLink = ({
  href,
  children,
  className,
  areaLabel,
}: UnderlineLinkProps) => {
  return (
    <div className={`flex items-center gap-x-2 `}>
      <Link
        href={href}
        area-label={areaLabel}
        className={`flex items-center font-light border-b border-current py-1 transition-all duration-300 group hover:pl-4 hover:pr-1 ${className}`}
      >
        <>
          <span>{children}</span>
        </>
      </Link>{" "}
      <ArrowRight
        size={20}
        className="text-primary transition-all group-hover:ml-2 duration-300 -rotate-45"
      />
    </div>
  );
};

export default UnderlineLink;
