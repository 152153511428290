import dynamic from "next/dynamic";

// Import all animations statically
import canadaSaudiAnimation from "../../../../../public/animation/Canada-Saudi.json";
import canadaEgyptAnimation from "../../../../../public/animation/Canada-Egypt.json";
import egyptCanadaAnimation from "../../../../../public/animation/Egypt-Canada.json";
import egyptSaudiAnimation from "../../../../../public/animation/Egypt-Saudi.json";
import saudiCanadaAnimation from "../../../../../public/animation/Saudi-Canada.json";
import saudiEgyptAnimation from "../../../../../public/animation/Saudi-Egypt.json";

const LottiePlayer = dynamic(() => import("lottie-react"), { ssr: false });

// Map animations to their corresponding imports
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const animations: Record<string, any> = {
  CanadaSaudi: canadaSaudiAnimation,
  CanadaEgypt: canadaEgyptAnimation,
  EgyptCanada: egyptCanadaAnimation,
  EgyptSaudi: egyptSaudiAnimation,
  SaudiCanada: saudiCanadaAnimation,
  SaudiEgypt: saudiEgyptAnimation,
  default: canadaEgyptAnimation, // Use Canada-Egypt as the default
};

export default function DynamicLottiePlayer(props: { src: string }) {
  const animationData = props.src
    ? animations[props.src] || animations["default"] // Fallback to default if src key is invalid
    : animations["default"];

  console.log(props.src);

  return (
    <LottiePlayer
      animationData={animationData}
      loop={false}
      autoplay
      style={{ scale: 1.2, marginLeft: "3rem" }}
    />
  );
}
