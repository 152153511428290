import CurvyButton from "@modules/common/components/curvy-button";
import EmblaCarousel from "@modules/common/components/embla-carousel";
import UnderlineLink from "@modules/common/components/underline-link";
import Link from "next/link";

function Hero() {
  return (
    <div className="flex w-screen px-4 lg:px-[4.5rem] lg:h-[80vh] flex-col-reverse lg:flex-row gap-4">
      <div className="w-full lg:w-1/2 h-1/2  text-darkGreen content-center lg:h-auto lg:mr-10">
        <h1 className=" lg:text-[4vw] lg:text-6xl text-3xl">
          Turn Transactions <br />
          into Connections with <br />
          <span className="text-primary font-myFont">Our Loyalty Builder</span>
        </h1>

        <p className="text-lightGray text-lg lg:text-[1vw] lg:mt-10 mt-6 lg:w-3/4">
          Unlock AI-powered loyalty with smart, intuitive tools that boost
          customer engagement and drive business growth. Build strong meaningful
          relationships with your customers.
        </p>
        <div className="flex lg:mt-10 mt-6 gap-6">
          <Link href="/#contact" passHref>
            <CurvyButton className="bg-primary text-terryWhite py-3 px-3 rounded-full w-36 justify-center">
              Get started
            </CurvyButton>
          </Link>
          <UnderlineLink
            className="text-darkGreen"
            href="/#products"
            areaLabel="View our products"
          >
            View our products
          </UnderlineLink>
          {/* <Button className="text-darkGreen text-lg">View all products</Button> */}
        </div>
      </div>
      <div className="overflow-clip rounded-[2.5rem] w-full lg:w-1/2 relative h-[calc(1.041*(100vw-2rem))] lg:h-auto">
        <EmblaCarousel
          srcList={["/hero-1.jpg", "/hero-2.jpg", "/hero-3.jpg", "/hero-4.jpg"]}
          objectFit="scale-down"
          width={1268}
          height={1320}
          layout="responsive"
          loading="eagerFirst"
        />
      </div>
    </div>
  );
}

export default Hero;
