export const clientsByCountry = {
  egypt: [
    {
      name: "JJs Burgers",
      logoSvgSrc: "/client-logos/Logos-1.svg",
      link: "https://jjs.prepit.app",
    },
    {
      name: "Alameed coffee",
      logoSvgSrc: "/client-logos/Logos-2.svg",
      link: "https://alameed.prepit.app",
    },
    {
      name: "1980",
      logoSvgSrc: "/client-logos/Logos-3.svg",
      link: "https://1980.prepit.app",
    },
    {
      name: "Brown Nose",
      logoSvgSrc: "/client-logos/Logos-4.svg",
      link: "https://brownnose.prepit.app",
    },
    {
      name: "Caribou Coffee",
      logoSvgSrc: "/client-logos/Logos-5.svg",
      link: "https://cariboucoffee.prepit.app",
    },
    // {
    //   name: "Dara's Icecream",
    //   logoSvgSrc: "/client-logos/Logos-6.svg",
    //   link: "https://daras.prepit.app",
    // },
    {
      name: "Gracias",
      logoSvgSrc: "/client-logos/Logos-7.svg",
      link: "https://gracias.prepit.app",
    },
    {
      name: "Munch & Shake",
      logoSvgSrc: "/client-logos/Logos-8.svg",
      link: "https://munchandshake.prepit.app",
    },
    {
      name: `Lodo's`,
      logoSvgSrc: "/client-logos/Logos-9.svg",
      link: "https://ladospizza.prepit.app",
    },
    {
      name: "Holy Buns",
      logoSvgSrc: "/client-logos/Logos-10.svg",
      link: "https://holybuns.prepit.app",
    },
    {
      name: "Vasko Coffee",
      logoSvgSrc: "/client-logos/Logos-11.svg",
      link: "https://vasko.prepit.app",
    },
    {
      name: "Mulliri Coffee",
      logoSvgSrc: "/client-logos/Logos-13.svg",
      link: "https://mulliri.prepit.app",
    },
  ],
  canada: [
    {
      name: "Allepo Kebab",
      logoSvgSrc: "/client-logos/AleppoKebab.png",
      link: "https://aleppo.prepit.app",
    },
    {
      name: "Azkadenya",
      logoSvgSrc: "/client-logos/Azkadenya.png",
      link: "https://azkadenya.prepit.app",
    },
    {
      name: "Kajun",
      logoSvgSrc: "/client-logos/Kajun.png",
      link: "https://kajun.prepit.app",
    },
    {
      name: "Lazeez",
      logoSvgSrc: "/client-logos/Lazeez.png",
      link: "https://lazeez.prepit.app",
    },
    {
      name: "Doughbits",
      logoSvgSrc: "/client-logos/doughbits.png",
      link: "https://doughbits.prepit.app",
    },
    {
      name: "Sultan's",
      logoSvgSrc: "/client-logos/Sultans.png",
      link: "https://sultans.prepit.app",
    },
  ],
  saudi: [],
};
